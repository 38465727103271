<template>
  <section>
    <dialogModule width="820px" title="收件人信息" top="15" v-if="salesVisiable" :dialogVisible="salesVisiable" @close="close">
      <span slot="close" class="el-icon-close" style="color: #999; padding: 10px; margin-right: -10px; cursor: pointer"
        @click="close"></span>
      <div slot="content" style="padding: 15px">
        <div class="box space-center">
          <div class="row align-center space-center">
            <div class="check-btn box-btn" v-if="type === '0'" style="margin-right: 10px">寄件到客</div>
            <div class="check-btn box-btn" v-if="type === '1'">寄件到店</div>
          </div>
          <div style="margin-top: 35px;">
            <div class="row align-center" style="margin-bottom: 20px;">
              <div style="width: 130px;text-align: right;margin-right: 10px;color: #0b83f3;"><i
                  class="el-icon-edit"></i>智能解析地址</div>
              <el-input v-model="addressAll" type="textarea" :disabled="type !== '0'"
                placeholder="在此处粘贴整段地址，自动识别姓名，手机号码和地址例：张三，16478909988，广东省广州市白云区大石区明朗创意园" :rows="3" @input="onAddressInput"
                resize="none"></el-input>
            </div>

            <div class="row align-center" style="margin-bottom: 20px;">
              <div style="width: 130px;text-align: right;margin-right: 10px;"><span
                  style="color: #F8475F;margin-right: 2px;">*</span>收件人姓名</div>
              <el-input v-model="formOne.receiverName" placeholder="请填写收件人姓名"></el-input>
            </div>
            <div class="row align-center" style="margin-bottom: 20px;">
              <div style="width: 130px;text-align: right;margin-right: 10px;"><span
                  style="color: #F8475F;margin-right: 2px;">*</span>手机号码</div>
              <el-input v-model="formOne.phone" placeholder="请填写收件人姓名"></el-input>
            </div>
            <div class="row align-center">
              <div class="row align-center" style="margin-bottom: 20px;">
                <div style="width: 154px;text-align: right;margin-right: 10px;"><span
                    style="color: #F8475F;margin-right: 2px;">*</span>省</div>
                <el-select v-model="formOne.province" placeholder="请选择省份" @change="getCityData" :disabled="type !== '0'"
                  filterable>
                  <el-option v-for="item in addressArray" :key="item.areacode" :label="item.name" :value="item.name">
                  </el-option>
                </el-select>
              </div>
              <div class="row align-center" style="margin-bottom: 20px;">
                <div style="width: 130px;text-align: right;margin-right: 10px;"><span
                    style="color: #F8475F;margin-right: 2px;">*</span>市</div>
                <el-select v-model="formOne.city" placeholder="请选择市" @change="getAreaData" :disabled="type !== '0'"
                  filterable>
                  <el-option v-for="item in cityArray" :key="item.areacode" :label="item.name" :value="item.name">
                  </el-option>
                </el-select>
              </div>
              <div class="row align-center" style="margin-bottom: 20px;">
                <div style="width: 130px;text-align: right;margin-right: 10px;"><span
                    style="color: #F8475F;margin-right: 2px;">*</span>区</div>
                <el-select v-model="formOne.area" placeholder="请选择区" @change="onAreaChanged" :disabled="type !== '0'"
                  filterable>
                  <el-option v-for="item in areaArray" :key="item.areacode" :label="item.name" :value="item.name">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="row align-center" style="margin-bottom: 20px;">
              <div style="width: 130px;text-align: right;margin-right: 10px;"><span
                  style="color: #F8475F;margin-right: 2px;">*</span>详细地址</div>
              <el-input type="textarea" v-model="formOne.address" row="5" :disabled="type !== '0'"
                placeholder="请填写详细地址"></el-input>
            </div>
          </div>
        </div>
        <div class="row align-center space-center bot-handel">
          <el-button @click="close">取 消</el-button>
          <el-button @click="submit" type="primary" :loading="loading">确 定</el-button>
        </div>
      </div>
    </dialogModule>
    <SmartMatching v-if="searchIng" @close="closeDialog" :guestBookingOrderNo="guestBookingOrderNo"></SmartMatching>
  </section>
</template>

<script>
import dialogModule from "@/components/dialogModule.vue";
import AddressParse from "address-parse";
import SmartMatching from "@/views/kedingModule/components/SmartMatching.vue";
import { queryDeptAddress, saveVisitorApply } from "@/libs/http/modules/customer";
import { queryCityInfoList } from "@/libs/http/api";
export default {
  components: {
    dialogModule,
    SmartMatching,
  },
  props: {
    type: {
      type: String,
      default: () => ''
    },
    goodsData: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      loading: false,
      salesVisiable: false,
      formOne: {
        applyType: '',
        receiverName: '',
        phone: '',
        province: '',
        city: '',
        area: '',
        address: ''
      },
      addressAll: "",
      searchIng: false,
      guestBookingOrderNo: '',
      addressArray: [],
      cityArray: [],
      areaArray: [],
    };
  },
  created () {
    this.salesVisiable = true;
    this.getAddressData();
    this.queryAddress();
  },
  methods: {
    getAddressData () {
      var that = this;
      queryCityInfoList()
        .then(data => {
          this.addressArray = data.nodes;
          if (that.formOne.sheng) {
            for (let ad of that.addressArray) {
              if (ad.name === that.formOne.sheng) {
                //默认赋值获取城市数组
                that.cityArray = ad.nodes;
                //默认赋值获取区域数组
                if (that.formOne.city) {
                  for (let area of that.cityArray) {
                    if (area.name === this.formOne.city) {
                      that.areaArray = area.nodes;
                      break;
                    }
                  }
                }
              }
            }
          }
        })
        .catch(err => {
          this.$message({ showClose: true, message: err, type: "error", duration: 2000 });
        });
    },
    getCityData (val) {
      this.formOne.city = "";
      this.formOne.area = "";
      for (let ad of this.addressArray) {
        if (ad.name === val) {
          this.cityArray = ad.nodes;
          return;
        }
      }
    },
    getAreaData (val) {
      this.formOne.area = "";
      for (let area of this.cityArray) {
        if (area.name === val) {
          this.areaArray = area.nodes;
          return;
        }
      }
    },
    onAreaChanged (val) {
      let areaId = "";
      for (let area of this.areaArray) {
        if (area.name === val) {
          areaId = area.area;
        }
      }
    },
    closeDialog () {
      this.$emit("close", "refresh");
    },
    queryAddress () {
      queryDeptAddress({
        id: this.$cache.local.get('deptId')
      }).then(data => {
        if (this.type === '1') {
          for (let key in data) {
            this.formOne[key] = data[key];
            if (key === 'principal') {
              this.formOne['receiverName'] = data[key];
            }
            if (key === 'principalPhone') {
              this.formOne['phone'] = data[key];
            }
          }
        }
      }).catch(err => { })
    },
    close () {
      this.$confirm('提示', {
        title: '提示',
        message: '是否确认取消？',
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(res => {
        this.salesVisiable = false;
        this.$emit("close");
      }).catch(err => { })
    },
    submit () {
      this.loading = true;
      let isNull = false;
      for (let key in this.formOne) {
        if (!this.formOne[key] && key !== 'applyType') {
          isNull = true
        }
      }
      if (isNull) return this.$message({ showClose: true, message: '请把信息填写完整', type: "error", duration: 2000 });
      let reqData = {
        applyType: this.type,
        receiverName: this.formOne.receiverName,
        province: this.formOne.province,
        city: this.formOne.city,
        area: this.formOne.area,
        address: this.formOne.address,
        phone: this.formOne.phone,
        applyGoodsInfoList: this.goodsData,
      };
      saveVisitorApply(reqData).then(data => {
        if (data) {
          this.$message({
            type: "success",
            message: "申请成功",
            duration: 2000,
            showClose: true,
          });
          this.loading = false;
          this.guestBookingOrderNo = data.guestBookingOrderNo;
          this.salesVisiable = false;
          this.searchIng = true;
          this.$emit('loadingFetch')
        }
      }).catch(err => {
        this.loading = false;
        this.$message({ showClose: true, message: err, type: "error", duration: 2000 });
      });
    },
    onAddressInput (text) {
      const parse = AddressParse.parse(text);
      if (parse && parse.length > 0) {
        const content = parse[0];
        this.formOne.province = content.province
        this.formOne.city = content.city
        this.formOne.area = content.area
        this.formOne.phone = content.mobile
        this.formOne.receiverName = content.name
        this.formOne.address = content.details
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.smart {
  color: #067cf2;
  width: 130px;
  margin-left: 20px;
  margin-right: 10px;
  font-family: "font-Regular";
}

::v-deep .write .el-input__inner {
  width: 300%;
}

::v-deep .write .el-textarea__inner {
  width: 320%;
}

::v-deep .writes .el-textarea__inner {
  width: 100%;
  background: #eeeeee;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  color: #000;

  &::placeholder {
    color: #F8475F;
  }
}

::v-deep .el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #333333;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
  font-family: "font-Regular";
}

.box {
  margin-top: 20px;

  .box-btn {
    width: 140px;
    height: 39px;
    border-radius: 4px;
    font-family: "font-Light";
    text-align: center;
    font-size: 18px;
    background: #eff7ff;
    line-height: 39px;
    color: #333333;
  }

  .check-btn {
    background: #067cf2;
    color: #ffffff;
  }

  .scan-search {
    margin: 70px auto 0;
    width: 350px;
  }
}

.bot-handel {
  text-align: center;
  margin: 40px auto 10px;
  font-family: "font-Regular";
  font-size: 16px;
}
</style>
