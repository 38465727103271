<template>
    <el-dialog
        title="客订申请"
        width="95%"
        top="10vh"
        :close-on-click-modal="false"
        :visible.sync="application"
        @close="close"
    >
        <section style="display: flex; height: 66vh; overflow-y: scroll">
            <section style="width: 70%; margin-right: 20px">
                <h3>新增货品明细</h3>
                <div class="searchA">
                  <span>货号</span>
                  <el-input size="mini" v-model="goodsNo" style="width: 200px; margin: 0 10px" @keyup.enter.native="fetch(true)"></el-input>
                  <el-button size="mini" type="primary" style="width: 85px" @click="fetch(true)" :loading="loading">搜索</el-button>
                </div>
                <div class="table">
                    <div class="table-head">
                        <div style="width: 150px">货号</div>
                        <div style="width: 150px">货品名称</div>
                        <div style="width: 90px">颜色</div>
                        <section
                            class="size-group"
                            style="
                                flex: 1;
                                border: 1px solid #e6ebf5;
                                border-top: none;
                                border-right: none;
                                height: 40px;
                            "
                        >
                            <div class="size-title" style="text-align: center; line-height: 40px">尺码组</div>
                        </section>
                    </div>

                    <div class="table-body" id="scrollDiv" ref="tabBody" v-loading="loading">
                        <el-empty description="暂无数据" v-if="!dataList.length" style="margin-top: 100px"></el-empty>
                        <template v-else>
                            <div v-for="(item, index) in dataList" :key="index" class="body-row">
                                <div
                                    style="
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                        display: block;
                                        line-height: 40px;
                                        width: 150px;
                                    "
                                    :title="item.spu"
                                >
                                    {{ item.spu }}
                                </div>
                                <div style="width: 150px">{{ item.goodsName }}</div>
                                <div style="width: 90px">{{ item.color }}</div>
                                <div
                                    style="width: 50px; border: 1px solid #ccc"
                                    v-for="(e, i) in item.sizeGroupInfos"
                                    :key="i"
                                    :class="e.value === rowSize.value && rowSize.spu === item.spu ? 'active' : 'deActive'"
                                    @click="rowSizeGet(e, item)">
                                    {{ e.value }}
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="[10, 20, 30, 50]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    background
                    :total="total"
                >
                </el-pagination>
            </section>
            <section style="flex: 1">
                <div class="askGoods">
                    <el-button type="primary" @click="storeGoods('1')">店铺要货</el-button>
                    <el-button type="warning" @click="storeGoods('0')">客户要货</el-button>
                </div>
                <div>
                    <h3>已添加客订货品</h3>
                    <div class="tableB">
                        <div class="table-head">
                            <div style="width: 90px">货号</div>
                            <div style="width: 120px">货品名称</div>
                            <div style="width: 70px">颜色</div>
                            <div style="width: 70px">尺码</div>
                            <div style="width: 140px">数量</div>
                            <div style="width: 90px">操作</div>
                        </div>
                        <div class="table-body" ref="tabBody">
                          <el-empty
                              description="请单击左侧尺码单元格添加客订货品"
                              v-if="!selectedData.length"
                              style="margin-top: 100px"
                          ></el-empty>
                            <template v-else>
                                <div v-for="(item, index) in selectedData" :key="index" class="body-row">
                                    <div
                                        style="
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            white-space: nowrap;
                                            display: block;
                                            line-height: 40px;
                                            width: 91px;
                                        "
                                        :title="item.spu"
                                    >
                                        {{ item.spu }}
                                    </div>
                                    <div style="width: 120px">{{ item.goodsName }}</div>
                                    <div style="width: 70px">{{ item.color }}</div>
                                    <div style="width: 70px">{{ item.value }}</div>
                                    <div style="width: 140px">
                                        <el-input-number
                                            :min="0"
                                            :precision="0"
                                            style="width: 100%"
                                            v-model="item.number"
                                        ></el-input-number>
                                    </div>
                                    <div
                                        style="width: 90px; cursor: pointer; color: #077cf2; text-decoration: underline"
                                        @click="deleteData(index)"
                                    >
                                        删除
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </section>
        </section>
        <keDingTips v-if="tipsLook" @close="sendClose" :list="hasStockList"></keDingTips>
        <WriteReceiverInfo
            v-if="WriteLoading"
            :type="type"
            @close="storeClose"
            @loadingFetch="loadingFetch"
            :goodsData="goodsData"
        ></WriteReceiverInfo>
    </el-dialog>
</template>

<script>
import WriteReceiverInfo from './WriteReceiverInfo.vue'
import keDingTips from './KedingTips.vue'
import SearchFormItem from '@/components/SearchFormItem/SearchFormItem'
import { posVisitorBooking, visitorCheckApply } from '@/libs/http/modules/customer'
export default {
    name: 'StoreInventoryBalance',
    components: {
        SearchFormItem,
        keDingTips,
        WriteReceiverInfo
    },
    data() {
        return {
            WriteLoading: false,
            type: '',
            tipsLook: false,
            rowSize: {},
            application: true,
            dataList: [],
            loading: false,
            currentPage: 1,
            total: 0,
            pageSize: 10,
            selectedData: [],
            goodsNo: '',
            goodsData: [],
            hasStockList: []
        }
    },
    created() {
        this.fetch();
    },
    methods: {
        loadingFetch() {
          this.$emit('loadingFetch')
        },
        deleteData(index) {
          this.$confirm('提示', {
            title: '提示',
            message: '确定要删除当前客订货品？',
            confirmButtonText: '确定',
            cancelButtonText: '取消'
          }).then((res) => {
            this.selectedData.splice(index, 1)
          }).catch((err) => {})
        },
        storeGoods(type) {
          if(!this.selectedData.length) return this.$message({ type: 'error', message: '请先添加客订货品', duration: 2000, showClose: true })
          const checkNumber = this.selectedData.every((item) => {
            return !item.number || (+item.number) === 0
          })
          if(checkNumber) return this.$message({ type: 'error', message: '请正确填写数量(数量不可小于或等于0)', duration: 2000, showClose: true })
          this.hasStockList = this.selectedData.filter((item) => {
            return (+item.usableStock) > 0
          })
          this.type = type
          this.goodsData = this.selectedData.map(item=>{
            return{
              goodsNo: item.goodsNo,
              sku: item.sku,
              number: item.number,
              price: item.price
            }
          })
          this.queryConfig()
        },
        queryConfig(){
          visitorCheckApply({
            applyGoodsInfoList: this.goodsData
          }).then(data=>{
            if(this.hasStockList.length){
              this.tipsLook = true
            }else{
              this.WriteLoading = true
            }
          }).catch(error=>{
            this.$message({ type: 'error', message: error, duration: 2000, showClose: true })
          })
        },

        rowSizeGet(e, item) {
          e.spu = item.spu
          this.rowSize = e
          let index = this.selectedData.findIndex(list=>{
            return list.spu === item.spu && list.value === e.value
          })
          if(index === -1){
            this.selectedData.push({
              sku: e.sku,
              number: '1',
              spu: item.spu,
              price: e.price,
              value: e.value,
              goodsNo: item.spu,
              color: item.color,
              goodsName: item.goodsName,
              usableStock: e.usableStock
            })
          }
        },
        sendClose(e) {
          this.tipsLook = false
          if(e === 'refresh'){
            this.$nextTick(()=>{
              this.WriteLoading = true
            })
          }
        },
        close() {
          this.application = false
          this.$emit('close')
        },
        storeClose(e) {
          this.WriteLoading = false
          if(e === 'refresh'){
            this.application = false
            this.$emit('close', 'refresh')
          }
        },
        filterRepeat(arrList, key) {
          let map = new Map()
          return arrList.filter((item) => !map.has(item[key].toString()) && map.set(item[key].toString()))
        },
        fetch(page) {
            if (page) {
                this.currentPage = 1
            }
            if(this.loading) return
            this.loading = true
            posVisitorBooking({
              page: this.currentPage,
              pageSize: this.pageSize,
              goodsNo: this.goodsNo
            }).then((data) => {
              this.loading = false
              data.items.forEach(sku=>{
                sku.sizeGroupInfos = this.filterRepeat(sku.sizeGroupInfos, 'value')
              })
              this.dataList = data.items || []
              this.total = Number(data.totalItem) || 0
              this.totalPage = Number(data.totalPage) || 1
            }).catch((error) => {
              this.loading = false
              this.$message({ showClose: true, message: error, type: 'error', duration: 2000 })
            })
        },
        handleSizeChange(val) {
            this.pageSize = val
            this.currentPage = 1
            this.$nextTick(() => {
                this.fetch()
            })
        },
        handleCurrentChange(val) {
            this.currentPage = val
            this.$nextTick(() => {
                this.fetch()
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.table {
    font-size: 14px;
    box-sizing: border-box;
    border: 1px solid #e6ebf5;
    height: 560px;
    display: flex;
    flex-direction: column;

    .table-head {
        display: flex;
        background: #fafafa;

        > div {
            height: 40px;
            text-align: center;
            border: 1px solid #e6ebf5;
            border-right: none;
            border-top: none;
            display: flex;
            justify-content: center;
            align-items: center;

            &:first-child {
                border-left: none;
            }
        }

        .size-list {
            div {
                span {
                    width: 50px;
                    border: 1px solid #e6ebf5;
                    display: inline-block;
                    border-bottom: none;
                    border-left: none;
                    text-align: center;
                    line-height: 29px;
                    height: 29px;
                    background: #fff;
                }

                &:last-child span {
                    border-bottom: 1px solid #e6ebf5;
                }
            }
        }
    }

    .table-body {
        overflow-y: scroll;
        border-top: none;
        border-top: none;

        .body-row {
            display: flex;

            > div {
                text-align: center;
                border: 1px solid #e6ebf5;
                // border-right: none;
                border-left: none;
                border-top: none;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                &:first-child {
                    border-left: none;
                }
            }
        }

        .body-size {
            span {
                width: 50px;
                display: inline-block;
                border-bottom: none;
                border-left: none;
                text-align: center;
                line-height: 30px;
                border-right: 1px solid #e6ebf5;
                border-bottom: 1px solid #e6ebf5;
            }

            &:last-child span {
                border-bottom: none;
            }
        }
    }

    .current-group {
        span {
            background: rgb(194, 234, 252) !important;
        }
    }

    .current-row {
        background: #ebf7ff;

        div {
            background: #ebf7ff;
        }
    }

    .green {
        background-color: #43d268 !important;
    }

    .red {
        color: white !important;
        background-color: #f8475f !important;
    }
}

.tableB {
    margin-top: 20px;
    font-size: 14px;
    box-sizing: border-box;
    border: 1px solid #e6ebf5;
    height: 608px;
    display: flex;
    flex-direction: column;

    .table-head {
        display: flex;
        background: #fafafa;

        > div {
            height: 40px;
            text-align: center;
            border: 1px solid #e6ebf5;
            border-right: none;
            border-top: none;
            display: flex;
            justify-content: center;
            align-items: center;

            &:first-child {
                border-left: none;
            }
        }

        .size-list {
            div {
                span {
                    width: 50px;
                    border: 1px solid #e6ebf5;
                    display: inline-block;
                    border-bottom: none;
                    border-left: none;
                    text-align: center;
                    line-height: 29px;
                    height: 29px;
                    background: #fff;
                }

                &:last-child span {
                    border-bottom: 1px solid #e6ebf5;
                }
            }
        }
    }

    .table-body {
        flex: 1;
        overflow-y: scroll;
        border-top: none;
        border-top: none;

        .body-row {
            display: flex;

            > div {
                text-align: center;
                border: 1px solid #e6ebf5;
                // border-right: none;
                border-left: none;
                border-top: none;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                &:first-child {
                    border-left: none;
                }

                .el-input::v-deep {
                    background: #fef9ef;
                }
            }
        }

        .body-size {
            span {
                width: 50px;
                display: inline-block;
                border-bottom: none;
                border-left: none;
                text-align: center;
                line-height: 30px;
                border-right: 1px solid #e6ebf5;
                border-bottom: 1px solid #e6ebf5;
            }

            &:last-child span {
                border-bottom: none;
            }
        }
    }
}

.searchA {
    margin: 20px 0;
}

.askGoods {
    position: absolute;
    top: 25px;
    right: 60px;
    display: flex;

    > div {
        cursor: pointer;
        padding: 8px 23px;
        margin-left: 10px;
        border-radius: 3px;
        color: white;
    }
}

.active {
    background: #077cf2;
    color: white;
}

.deActive {
    background: #fef9ef;
}
</style>
