<template>
  <dialogModule
    width="466px"
    top="25"
    title="智能匹配代发单位"
    v-if="salesVisiable"
    :dialogVisible="salesVisiable"
    @close="close">
    <span slot="close" class="el-icon-close" style="color: #999; padding: 10px; margin-right: -10px; cursor: pointer" @click="close"></span>
    <div slot="content" style="padding: 15px 15px 30px;">
      <div class="text-center">
        <img src="@/assets/images/sutied.png" />
        <p style="color:#067CF2;font-size:16px;margin: 30px auto 10px;">系统正在智能匹配代发店铺，需要一定时间</p>
        <p>找到本单的客订详情可查看匹配结果</p>
        <p @click="close" style="padding:10px 20px;background:#067cf2;color:#fff;margin:10px 50px;cursor:pointer">知道了 ({{curtTime}})</p>
      </div>
    </div>
  </dialogModule>
</template>

<script>
import dialogModule from "@/components/dialogModule.vue"
import {visitorBooking} from "@/libs/http/modules/customer"
export default {
  props: ['guestBookingOrderNo'],
  components: {
    dialogModule,
  },
  data() {
    return {
      salesVisiable: true,
      resultData: null,
      timer: null,
      curtTime: 5,
    };
  },
  created() {
    this.queryNearlyDept();
    this.startTimer();
  },
  methods: {
    close() {
      this.salesVisiable = false;
      this.$emit("close");
      clearInterval(this.timer);
      this.timer = null;
    },
    queryNearlyDept(){
      visitorBooking({
        guestBookingOrderNo: this.guestBookingOrderNo
      }).then(data=>{
        this.resultData = data;
      }).catch(err=>{
        this.$message({type:'error', message:err, duration:2000, showClose:true});
      })
    },
    startTimer(){
      clearInterval(this.timer);
      this.timer = null;
      this.timer = setInterval(()=>{
        this.curtTime--
        if(this.curtTime === 0){
          this.close()
        }
      }, 1000)
    },
  },
  destroyed() {
    clearInterval(this.timer);
    this.timer = null;
  }
};
</script>

<style lang="scss" scoped>
.info {
    margin: 43px auto;
}
.typeCity {
  margin: 0 9px 0 20px;
  width: 56px;
  height: 25px;
  background: #fff8ef;
  border: 1px solid #ff9000;
  border-radius: 4px;
  text-align: center;
  line-height: 25px;
  color: #ff9000;
  font-size: 14px;
}
.distance {
  width: 153px;
  height: 25px;
  background: #f5ffef;
  border: 1px solid #43d268;
  border-radius: 4px;
  color: #43D268;
  text-align: center;
  line-height: 25px;
  font-size: 14px;
}
.text {
  color: #333333;
  font-size: 18px;
  text-align: center;
}
.box {
  margin-top: 40px;
  .succese-img {
    margin: auto;
  }
}
.sure-send{
  color:#fff;
  background:#067CF2;
  border-radius:4px;
  font-size:16px;
  width:300px;
  margin:auto;
  font-family: 'font-Regular';
  text-align:center;
  line-height:40px;
  padding: 0;
}
</style>

<style scoped>
.box .el-button.is-disabled, .box .el-button.is-disabled:focus, .box .el-button.is-disabled:hover{
  background: #067CF2;
  color:#fff;
}
</style>
