<template>
  <el-dialog
    :title="type === '0' ? '同意' : '拒绝'"
    width="30%"
    top="20vh"
    :visible.sync="dialogVisible"
    @close="close">
    <section style="margin: -20px 0px;text-align: center;font-size: 16px;">
      <el-input type="textarea" row="5" placeholder="请输入备注" v-model="describe"></el-input>
    </section>
    <span slot="footer">
      <el-button size="small" @click="close">取 消</el-button>
      <el-button size="small" type="primary" :loading="loading" :disabled="loading" @click="sureSend">确 认</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {deptProcessApply, visitorBooking} from "@/libs/http/modules/customer";
export default {
  name: 'Action',
  props: {
    currentObj: {
      type: Object,
      default: () => null
    },
    type: {
      type: String,
      default: () => ''
    }
  },
  data(){
    return{
      dialogVisible: true,
      goodsList: [],
      describe: '',
      loading: false
    }
  },
  methods: {
    close(type){
      this.dialogVisible = false;
      this.$emit('close', type);
    },
    sureSend(){
      this.loading = true;
      deptProcessApply({
        appId: this.currentObj.id,
        guestBookingOrderNo: this.currentObj.guestBookingOrderNo,
        action: this.type,
        describe: this.describe
      }).then(data=>{
        this.$message({type:'success', message: '操作成功', duration:2000, showClose:true});
        if(this.type === '1'){
          this.queryNearlyDept();
        }else{
          this.loading = false;
          this.close('refresh');
        }
      }).catch(error=>{
        this.loading = false;
        this.$message({type:'error', message: error, duration:2000, showClose:true});
      })
    },
    queryNearlyDept(){
      visitorBooking({
        guestBookingOrderNo: this.currentObj.guestBookingOrderNo
      }).then(data=>{
        this.loading = false;
        this.close('refresh');
      }).catch(err=>{
        this.loading = false;
        this.close('refresh');
      })
    }
  }
}
</script>