<template>
  <dialogModule
    width="466px"
    title="下单结果"
    v-if="orderResultsVisiable"
    :dialogVisible="orderResultsVisiable"
    @close="close">
    <span slot="close" class="el-icon-close" style="color: #999; padding: 10px; margin-right: -10px; cursor: pointer" @click="close"></span>
    <div slot="content" style="padding: 15px">
      <div class="box column space-center">
        <div class="succese-img column space-center align-center">
          <img src="@/assets/images/succese.png" style="width: 66px; height: 66px; margin-bottom: 16px" />
          <div class="text">下单成功</div>
        </div>
        <div class="text" style="margin: 38px auto 28px">运单号：{{ expressNo }}</div>
      </div>
    </div>
  </dialogModule>
</template>

<script>
import dialogModule from "@/components/dialogModule.vue";
export default {
  props: ['expressNo'],
  components: {
    dialogModule,
  },
  data() {
    return {
      orderResultsVisiable: true,
    };
  },
  methods: {
    close() {
      this.orderResultsVisiable = false;
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.text {
  color: #333333;
  font-size: 18px;
  text-align: center;
}

.box {
  .succese-img {
    margin: auto;
  }
}
</style>