<template>
  <section class="container">
    <stockTopMenu
      curPosition="客订记录"
      windowId="customerOrder"
      module="keding"
    ></stockTopMenu>
    <nav class="nav-tab row align-center text-center">
      <div
        class="row align-center space-center"
        :class="[chooseTab === '1' ? 'choose-tab' : '']"
        @click="changeTab('1')"
      >
        <span>本店需要</span>
        <span v-if="Number(thisDeptNeeds) > 0" class="span-num">{{
          thisDeptNeeds
        }}</span>
      </div>
      <div
        class="row align-center space-center"
        :class="[chooseTab === '2' ? 'choose-tab' : '']"
        @click="changeTab('2')"
      >
        <span>其他店铺需要</span>
        <span v-if="Number(otherDeptNeeds) > 0" class="span-num">{{
          otherDeptNeeds
        }}</span>
      </div>
    </nav>
    <OurStore
      v-show="chooseTab === '1'"
      :listId="winObj"
      :key="keyAll"
    ></OurStore>
    <OtherStore v-show="chooseTab === '2'"></OtherStore>
  </section>
</template>

<script>
import stockTopMenu from "@/components/stockTopMenu";
import OtherStore from "@/views/kedingModule/pages/otherStore";
import OurStore from "@/views/kedingModule/pages/ourStore";
import { statisticsApplyCount } from "@/libs/http/modules/customer";
import { specialIpcRoute } from "@/api/main";
import { ipc } from "@/utils/ipcRenderer";
export default {
  name: "customerOrder",
  components: {
    stockTopMenu,
    OurStore,
    OtherStore,
  },
  data() {
    return {
      chooseTab: "1",
      thisDeptNeeds: "",
      otherDeptNeeds: "",
      winObj: "",
      keyAll: 1,
    };
  },
  created() {
    this.fetchTotal();
  },
  mounted() {
    ipc.on(specialIpcRoute.window1ToWindow2, (event, arg) => {
      this.chooseTab = "1";
      this.winObj = arg.params;
      this.keyAll = this.keyAll + 1;
    });
    if (typeof JSON.parse(this.$common.getUrlParams("params")) == "string") {
      this.winObj = JSON.parse(this.$common.getUrlParams("params"));
    }
  },
  methods: {
    changeTab(i) {
      if (this.chooseTab === i) return;
      this.chooseTab = i;
    },
    // ss() {
    //   this.winObj = JSON.parse(this.$common.getUrlParams("params"));
    // },
    fetchTotal() {
      statisticsApplyCount()
        .then((data) => {
          this.thisDeptNeeds = data.thisDeptNeeds || 0;
          this.otherDeptNeeds = data.otherDeptNeeds || 0;
        })
        .catch((error) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-tab {
  margin: 10px 10px 0;

  div {
    width: 150px;
    height: 44px;
    margin-right: 10px;
    cursor: pointer;
    background: #fff;

    .span-num {
      margin-top: 2px;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      background: #f8475f;
      color: #fff;
      margin-left: 5px;
      font-size: 14px;
      font-weight: bold;
    }
  }

  .choose-tab {
    background: rgb(255, 242, 0);
  }
}
</style>
