<template>
  <el-dialog
    title="物流轨迹"
    width="50%"
    top="10vh"
    :visible.sync="dialogVisible"
    @close="close">
    <div style="margin: -20px;">
      <div>
        <i style="width: 4px;height: 20px;float: left;background: #0B83F3;border-radius: 2px;margin-right: 16px;margin-top: 2px;"></i>
        <span style="font-size: 16px;color: #333;">快递/地址信息</span>
      </div>
      <div style="margin: 20px;border: 1px solid #ddd;">
        <div class="row align-center" style="background: #eee;padding: 8px 15px;border-bottom: 1px solid #ddd;">
          <div class="row align-center space-center" style="width: 20px;height: 20px;border-radius: 50%;background: #ddd;font-size: 0;"><img src="@/assets/images/jd.png" style="width: 10px;" /></div>
          <span style="margin: 0 10px;">物流单号</span>
          <span v-if="applyMatchSkuLog">{{applyMatchSkuLog.expressNo}}</span>
        </div>
        <div class="row" style="margin: 15px;">
          <div class="row" style="width: 50%;" v-if="applyMatchLog">
            <div class="row align-center space-center" style="width: 80px;height: 80px;margin-right: 10px;background: rgb(235,247,255);color: #0B83F3;">发出方</div>
            <div class="column space-between" style="flex: 1;">
              <p style="font-size: 16px;color: #000;font-weight: bold;color: #F8475F;">{{applyMatchLog.deptName}}</p>
              <span style="color: #000;">{{applyMatchLog.principalPhone}}</span>
              <div style="color: #999;font-size: 14px;">
                {{applyMatchLog.province}}{{applyMatchLog.city}}{{applyMatchLog.area}}{{applyMatchLog.address}}
              </div>
            </div>
          </div>
          <div class="row" style="width: 50%;" v-if="receiverInfo">
            <div class="row align-center space-center" style="width: 80px;height: 80px;margin-right: 10px;background: rgb(235,247,255);color: #0B83F3;">接收方</div>
            <div class="column space-between" style="flex: 1;">
              <p style="font-size: 16px;color: #000;font-weight: bold;color: #F8475F;">{{receiverInfo.name}}</p>
              <span style="color: #000;">{{receiverInfo.phone}}</span>
              <div style="color: #999;font-size: 14px;">
                {{receiverInfo.province}}{{receiverInfo.city}}{{receiverInfo.area}}{{receiverInfo.address}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <i style="width: 4px;height: 20px;float: left;background: #0B83F3;border-radius: 2px;margin-right: 16px;margin-top: 2px;"></i>
        <span style="font-size: 16px;color: #333;">发货物流轨迹</span>
      </div>
      <div style="margin: 15px;" v-if="this.applyMatchSkuLog && this.applyMatchSkuLog.queryType === '0'">
        <grid-manager :option="gridOption"></grid-manager>
      </div>
      <div v-else>
        <webview :src="queryLink" style="width: 100%;height:400px;"></webview>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {posLogistics} from "@/libs/http/modules/posOrder";
export default {
  name: 'strackDialog',
  props: {
    applyMatchSkuLog: {
      type: Object,
      default: () => null
    },
    applyMatchLog: {
      type: Object,
      default: () => null
    },
    receiverInfo: {
      type: Object,
      default: () => null
    },
  },
  data(){
    return {
      dialogVisible: true,
      gridOption: {
        gridManagerName: "starckdialog",
        firstLoading: true,
        height: "400px",
        isCombSorting: true,
        supportAutoOrder: true,
        supportCheckbox: false,
        supportConfig: true,
        supportMenu: false,
        autoOrderConfig: {
          width: 100,
        },
        columnData: [
          {
            key: "opeTime",
            text: "时间",
            align: "center",
            width: '250px'
          },
          {
            key: "opeRemark",
            text: "轨迹",
            align: "center"
          }
        ],
        ajaxData: this.fetch,
      },
      list: [],
      queryLink: ''
    }
  },
  created() {
    if(this.applyMatchSkuLog){
      if(this.applyMatchSkuLog.queryType === '0'){
        this.fetchStrac();
      }else if(this.applyMatchSkuLog.queryType === '1'){
        this.queryLink = this.applyMatchSkuLog.queryUrl + this.applyMatchSkuLog.expressNo;
      }else{
        this.queryLink = this.applyMatchSkuLog.queryUrl + this.applyMatchSkuLog.expressNo;
      }
    }
  },
  methods: {
    close(){
      this.dialogVisible = false;
      this.$emit('close');
    },
    fetchStrac(){
      posLogistics({
        expressNo: this.applyMatchSkuLog.expressNo
      }).then(data=>{
        if(data && data.length){
          this.list = data;
          GridManager.refreshGrid(this.gridOption.gridManagerName);
        }else{
          this.list = [];
          GridManager.refreshGrid(this.gridOption.gridManagerName);
        }
      }).catch(error=>{
        this.$message({showClose: true, message: error, type: "error", duration: 2000})
      })
    },
    fetch(){
      return new Promise((resolve, reject) => {
        const tableData = {
          data: this.list
        };
        resolve(tableData);
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
