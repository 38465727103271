<template>
  <dialogModule
    width="600px"
    title="物流信息"
    v-if="salesVisiable"
    :dialogVisible="salesVisiable"
    @close="close">
    <span slot="close" class="el-icon-close" style="color: #999; padding: 10px; margin-right: -10px; cursor: pointer"  @click="close"></span>
    <div slot="content" style="padding: 15px">
      <div class="box space-center">
        <div class="row align-center space-center">
          <div :class="['box-btn', isCheck ? 'check-btn' : '']" style="margin-right: 10px" @click="isCheck = true">线上下单</div>
          <div v-if="isHands === 'Y'" :class="['box-btn', !isCheck ? 'check-btn' : '']" @click="isCheck = false">线下下单</div>
        </div>
        <div style="margin-top: 50px">
          <div v-if="isCheck" style="margin-bottom: 60px;margin-left: 30px;">
            <div style="font-size: 16px;color: #000;margin-bottom: 10px;">预估重量：{{weight || '0'}}公斤（根据货品资料克重计算）</div>
            <span style="border-radius: 4px;background: rgb(255,247,230);padding: 5px 10px;color: rgb(212,107,8);">
              {{weight > 30 ? '当前重量>30公斤，将下单京东快运' : '当前重量<30公斤，将下京东快递'}}
            </span>
          </div>
          <el-form v-else inline label-width="105px" :model="guestForm" :rules="guestFormRules">
            <el-row :gutter="24">
              <el-col :span="24">
                <el-form-item label="物流公司" prop="logisticsId">
                  <el-select
                    v-model="guestForm.logisticsId"
                    placeholder="请选择"
                    style="width: 100%;"
                    filterable
                    remote
                    class="selec">
                    <el-option
                      v-for="item in kuaidiList"
                      :key="item.logisticsId"
                      :label="item.logisticsName"
                      :value="item.logisticsId">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="快递单号" prop="expressNo">
                  <el-input
                      v-model="guestForm.expressNo"
                      placeholder="请输入快递单号"
                      class="write"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <div class="row align-center space-center">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="submit" :loading="loading" :disabled="loading">确 定</el-button>
      </div>
      <OrderResults
          v-if="OrderResultsVisiable"
          :expressNo="expressNo"
          @close="closeDialog"
      ></OrderResults>
    </div>
  </dialogModule>
</template>

<script>
import dialogModule from "@/components/dialogModule.vue";
import OrderResults from "./OrderResults.vue";
import {queryLogisticsList, confirmShipment} from "@/libs/http/modules/customer";
import {visitorBookingOrderWeight} from "@/libs/http/modules/posOrder";
import {queryNegativeManualExpress} from "@/libs/http/modules/erp";
export default {
  props: ["invoicesId", "currentObj"],
  components: {
    dialogModule,
    OrderResults,
  },
  data() {
    return {
      salesVisiable: false,
      isCheck: true,
      userList: [],
      guestFormRules: {
        logisticsId: [{ required: true, message: "请选择物流公司" }],
      },
      guestForm: {
        logisticsId: "1",
        expressNo: "",
      },
      kuaidiList: [],
      OrderResultsVisiable: false,
      expressNo: "",
      loading: false,
      weight: '',
      isHands: ''
    };
  },
  created() {
    this.salesVisiable = true;
    this.queryHands()
    this.queryWeight()
  },
  methods: {
    close(type) {
      this.salesVisiable = false;
      this.$emit("close", type);
    },
    closeDialog(){
      this.salesVisiable = false;
      this.close('refresh')
    },
    fetch() {
      queryLogisticsList().then(data => {
        this.kuaidiList = data;
      }).catch(err => {});
    },
    queryHands(){
      queryNegativeManualExpress().then(res=>{
        this.isHands = res
        if(this.isHands === 'Y'){
          this.fetch()
        }
      }).catch(error=>{})
    },
    queryWeight(){
      visitorBookingOrderWeight({
        id: this.currentObj.id
      }).then(res=>{
        if(res){
          this.weight = (res / 1000).toFixed(2)
        }
      }).catch(error=>{})
    },
    submit() {
      if(!this.guestForm.logisticsId) return this.$message({showClose: true, message: '请选择快递公司', type: "error", duration: 2000})
      this.loading = true;
      confirmShipment({
        applyId: this.currentObj.id,
        expressNo: this.guestForm.expressNo,
        logisticsId: this.guestForm.logisticsId,
        type: this.isCheck ? "1" : "2",
      })
      .then(data => {
        this.OrderResultsVisiable = true;
        this.expressNo = data.expressNo;
        this.loading = false;
      })
      .catch((err) => {
        this.loading = false;
        this.$message({showClose: true, message: err, type: "error", duration: 2000})
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .write .el-input__inner {
  width: 420px;
}

::v-deep .selec .el-input__inner {
  width: 420px;
}

::v-deep .el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #333333;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
  font-family: "font-Regular";
}

.box {
  margin-top: 20px;

  .box-btn {
    width: 140px;
    height: 39px;
    border-radius: 4px;
    font-family: "font-Light";
    text-align: center;
    font-size: 18px;
    cursor: pointer;
    background: #eff7ff;
    line-height: 39px;
    color: #333333;
  }

  .check-btn {
    background: #067cf2;
    color: #ffffff;
  }

  .scan-search {
    margin: 70px auto 0;
    width: 350px;
  }
}
</style>
